<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 列表数据 -->
		<div class="notices">
			<div class="notices-list" v-for="(item,id) in list" :key="id" @click="noticesDetails(item)">
				<el-row class="notices-list-extend">
					<el-col :span="8">
						<el-image :src='item.pictureUrl' class="notices-listimg"></el-image>
					</el-col>
					<el-col :span="13" :offset="1" class="notices-listdata">
						<el-row class="notices-listtitle">
							{{item.title}}
						</el-row>
						<el-row class="notices-listmessage" v-html="item.bodyContent">
							{{item.bodyContent}}
						</el-row>
					</el-col>
					<el-col :span="2" style="background:skyblue;">
						<div class="notices-date">
							<el-row class="notices-year">{{item.year}}</el-row>
							<el-row class="notices-time">{{item.time}}</el-row>
							<el-row class="notices-month">{{item.month}}</el-row>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'
	
// 头部组件
import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
// 头部导航
import moveNavigation from '@/components/move/moveNavigation.vue'
// 菜单导航
import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
// 底部导航
import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
export default
{
	data()
	{
		return{
			// 头部导航标题
			headTitle:'BREAKING NEWS & NOTICES',
			// 头部导航图片
			headImg:'/index/list.png',
			// 导航值
			navigationvalue:false,
			
			// 数据
			list:[]
		}
	},
	components:
	{
		moveHeadLogo,
		moveNavigation,
		moveMeauDialog,
		moveBottomNavigation,
	},
	created()
	{
		this.getNoticesList()
	},
	methods:
	{
		// 头部传来的数据
		navigation(v)
		{
			this.navigationvalue=v;
		},
		// 遮罩层传回来的数据
		menu(v)
		{
			this.navigationvalue=v;
		},
		
		// 获取公告数据
		getNoticesList()
		{
			selectContentListById(
			{
				chnanelType: 2,
				isIndex: 0,
				moduleType: 'MODULE_BRESAKING'
			})
			.then((response)=>
			{
				this.list=response.data.data;
				response.data.data.map((v)=>
				{
					v.pulish=v.publish.split('-')
					if(v.pulish[1]=='01')
					{
						v.pulish[1]='JAN'
					}
					else if(v.pulish[1]=='02')
					{
						v.pulish[1]='FEB'
					}
					else if(v.pulish[1]=='03')
					{
						v.pulish[1]='MAR'
					}
					else if(v.pulish[1]=='04')
					{
						v.pulish[1]='APR'
					}
					else if(v.pulish[1]=='05')
					{
						v.pulish[1]='MAY'
					}
					else if(v.pulish[1]=='06')
					{
						v.pulish[1]='JUN'
					}
					else if(v.pulish[1]=='07')
					{
						v.pulish[1]='JUL'
					}
					else if(v.pulish[1]=='08')
					{
						v.pulish[1]='AUG'
					}
					else if(v.pulish[1]=='09')
					{
						v.pulish[1]='SEP'
					}
					else if(v.pulish[1]=='10')
					{
						v.pulish[1]='OCT'
					}
					else if(v.pulish[1]=='11')
					{
						v.pulish[1]='NOV'
					}
					else if(v.pulish[1]=='12')
					{
						v.pulish[1]='DEC'
					}
					v.year=v.pulish[0];
					v.month=v.pulish[1];
					v.time=v.pulish[2];
				})
			})
		},
		
		// 资讯详情
		noticesDetails(item)
		{
			localStorage.setItem('Details',JSON.stringify(item));
			this.$router.push(
			{
				path:'/moveNoticesDetails',
				query:
				{
					item:item,
					headTitle:'BREAKING NEWS & NOTICES',
					headImg:'/index/list.png',
				}
			})
		}
	}
}

</script>

<style scoped>
	.notices{
		width: 100%;
	}
	.notices-list{
		width: 712px;
		height: 194px;
		margin-left:19px;
		margin-top: 20px;
		position: relative;
		border: 1px solid #CCCCCC;
	}
	.notices-listimg{
		width: 256px;
		height: 192px;
	}
	.notices-listdata{
		padding:0px 2% 0px 1%;
		text-align: left;
	}
	.notices-listtitle{
		margin-top: 33px;
		font-size: 18px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 26px;
		color: #505050;
	}
	.notices-listmessage{
		height: 50px;
		margin-top: 24px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		
		font-size: 20px;
		color: #505050;
		line-height:25px;
	}
	.notices-date{
		width: 53px;
		height: 88px;
		background: #EEEEEE;
		position: absolute;
		top: -8px;
		right: 10px;
	}
	.notices-year{
		height: 12px;
		font-size: 16px;
		font-family: Arial;
		color: #505050;
		margin-top: 12px;
	}
	.notices-month{
		margin-top: 10px;
		height: 13px;
		font-size: 17px;
		color: #505050;
	}
	.notices-time{
		margin-top: 5px;
		height: 26px;
		font-size: 36px;
		color: #505050;
	}
	.limit{
		margin-top: 20px;
	}
	.notices-list :hover{
		box-shadow: 1px 1px 10px #999;
	}
	.notices-list-extend :hover{
		box-shadow: none;
	}
</style>